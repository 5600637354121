import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

const Sidebar = ({ location }) => {
  const [menuState, setMenuState] = useState({});

  const toggleMenuState = (newMenuState) => {
    setMenuState((prevMenuState) => {
      const updatedState = { ...prevMenuState };

      if (updatedState[newMenuState]) {
        updatedState[newMenuState] = false;
      } else if (Object.keys(updatedState).length === 0) {
        updatedState[newMenuState] = true;
      } else {
        Object.keys(updatedState).forEach((key) => {
          updatedState[key] = false;
        });
        updatedState[newMenuState] = true;
      }

      return updatedState;
    });
  };

  const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(menuState).forEach((key) => {
      setMenuState((prevMenuState) => ({ ...prevMenuState, [key]: false }));
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setMenuState((prevMenuState) => ({ ...prevMenuState, [obj.state]: true }));
      }
    });
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    onRouteChanged();

    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', () => {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', () => {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }, [location.pathname]);

  return (

    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div>
        <ul className="tab-container">
          <li className={`tab-list ${location.pathname.includes('/') && location.pathname === '/' ? 'active' : ''}`}>
            <Link to="/" className="title" href="index.html">
              <i className="mdi mdi-home"></i>
            </Link>
          </li>
          <li className={`tab-list ${location.pathname.includes('/tenant-overview') || location.pathname.includes('/tenant-cloudops') || location.pathname.includes('/tenant-preprod-overview') || location.pathname.includes('/account-summary') || location.pathname.includes('/view-details') ? 'active' : ''}`} data-for="right">
            <Link to="/tenant-overview" className="title">
              <i className="mdi mdi-view-list"></i>
            </Link>
            <ul className="inner-sub-menu-list">
              <li className={`nav-item ${location.pathname.includes('/tenant-overview') ? 'active' : ''}`}>
                <Link className={isPathActive('/tenant-overview') ? 'nav-link active' : 'nav-link'} to="/tenant-overview">
                  <Trans>Prod Subscriptions</Trans>
                </Link>
              </li>
              <li className={`nav-item ${location.pathname.includes('/account-summary') ? 'active' : ''}`}>
                <Link className={isPathActive('/account-summary') ? 'nav-link active' : 'nav-link'} to="/account-summary">
                  <Trans>Prod Accounts Summary</Trans>
                </Link>
              </li>
              <li className={`nav-item ${location.pathname.includes('/tenant-cloudops') ? 'active' : ''}`}>
                <Link className={isPathActive('/tenant-cloudops') ? 'nav-link active' : 'nav-link'} to="/tenant-cloudops">
                  <Trans>CloudOps Subscriptions</Trans>
                </Link>
              </li>
              <li className={`nav-item ${location.pathname.includes('/tenant-preprod-overview') ? 'active' : ''}`}>
                <Link className={isPathActive('/tenant-preprod-overview') ? 'nav-link active' : 'nav-link'} to="/tenant-preprod-overview">
                  <Trans>Preprod Subscriptions</Trans>
                </Link>
              </li>
            </ul>
          </li>

          <li className={`tab-list ${location.pathname.includes('/customers-overview') || location.pathname.includes('/view-customer-details') || location.pathname.includes('/tenant-provisioning') ? 'active' : ''}`} data-for="right">
            <Link to="/customers-overview" className="title">
              <i className="mdi mdi-human-greeting"></i>
            </Link>
            <ul className="inner-sub-menu-list">
              <li className={`nav-item ${location.pathname.includes('/customers-overview') ? 'active' : ''}`}>
                <Link className={isPathActive('/customers-overview') ? 'nav-link active' : 'nav-link'} to="/customers-overview">
                  <Trans>Customers List</Trans>
                </Link>
              </li>
              <li className={`nav-item ${location.pathname.includes('/tenant-provisioning') ? 'active' : ''}`}>
                <Link className={isPathActive('/tenant-provisioning') ? 'nav-link active' : 'nav-link'} to="/tenant-provisioning">
                  <Trans>Provisioning Status</Trans>
                </Link>
              </li>
            </ul>
          </li>

          <li className={`tab-list ${location.pathname.includes('/alert/') ? 'active' : ''}`}>
            <Link className="title" to="/alert/expired">
              <i className="mdi mdi-alert"></i>
            </Link>
            <ul className="inner-sub-menu-list">
              <li className={`nav-item ${location.pathname.includes('/alert/expired') ? 'active' : ''}`}>
                <Link className={isPathActive('/alert/expired') ? 'nav-link active' : 'nav-link'} to="/alert/expired">
                  <Trans>Expired Subscriptions</Trans>
                </Link>
              </li>
              <li className={`nav-item ${location.pathname.includes('/alert/expiring') ? 'active' : ''}`}>
                <Link className={isPathActive('/alert/expiring') ? 'nav-link active' : 'nav-link'} to="/alert/expiring">
                  <Trans>Expiring Subscriptions</Trans>
                </Link>
              </li>
              <li className={`nav-item ${location.pathname.includes('/alert/misconfigured-tenant') ? 'active' : ''}`}>
                <Link className={isPathActive('/alert/misconfigured-tenant') ? 'nav-link active' : 'nav-link'} to="/alert/misconfigured-tenant">
                  <Trans>Flagged Subscriptions</Trans>
                </Link>
              </li>
              <li className={`nav-item ${location.pathname.includes('/alert/certificate-validity') ? 'active' : ''}`}>
                <Link className={isPathActive('/alert/certificate-validity') ? 'nav-link active' : 'nav-link'} to="/alert/certificate-validity">
                  <Trans>Certificate Validity</Trans>
                </Link>
              </li>
            </ul>
          </li>

        </ul>

        <ul className="tab-container tab-bottom">
          <li className={`tab-list ${location.pathname.includes('/help') ? 'active' : ''}`} data-tip="Help & References" data-for="right">
            <Link to="/help" className="title" href="index.html">
              <i className="mdi mdi-help-circle-outline"></i>
            </Link>
          </li>
        </ul>

        <ul className="nav">
          {/* Additional navigation items can be added here */}
        </ul>

        <ReactTooltip id="right" place="bottom" effect="solid"></ReactTooltip>

      </div>
    </nav>

  );
};

export default withRouter(Sidebar);